import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PhotoSwipeGallery } from 'react-photoswipe'
import Button from '../components/button'

const PhotoGallerySection = styled.section`
  img {
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    border-radius: 2px;
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 12px -2px;
    }
  }
  .pswp-thumbnails {
    display: flex;
    flex-wrap: wrap;
  }
  .pswp-thumbnail {
    flex: 0 0 25%;
    padding: 2%;
    @media (max-width: 992px) {
      flex: 0 0 33%;
    }
    @media (max-width: 767px) {
      flex: 0 0 50%;
    }
  }
`

const HeadingArea = styled.div`
  background: #ececec;
  text-align: center;
  padding: 50px 10px;
  padding-bottom: 100px;
  h1 {
    margin-bottom: 0.25em;
    font-size: calc(1vw + 1.35rem);
  }
  p {
    max-width: 767px;

    color: #666;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }
`

const ShowroomPage = props => {
  const { data } = props
  const items = data.allContentfulPhotoGallery.edges[0].node.photos.map(
    (item, i) => ({
      id: item.id,
      title: 'Solid Construction & Design Showroom',
      src: item.gatsbyImageData.images.fallback.src,
      thumbnail: item.thumbnail.images.fallback.src,
      w: 1200,
      h: 900,
    })
  )

  let options = {}

  const getThumbnailContent = item => (
    <img src={item.thumbnail} alt={item.title} />
  )

  return (
    <Layout pageProps={props}>
      <SEO
        title="Kitchen & Bathroom Showroom in Sacramento"
        description={
          'Looking for a kitchen bath showroom in Sacramento? Look no further than Solid Construction & Design. Visit our showroom for a wide selection of top-quality products and expert guidance on your renovation journey. Contact us today to get started!'
        }
      />
      <HeadingArea>
        <div className="container">
          <h1>
            Sacramento {data.allContentfulPhotoGallery.edges[0].node.title}
          </h1>
          <p>
            {
              data.allContentfulPhotoGallery.edges[0].node.description
                .description
            }
          </p>
          <a
            href={
              'http://maps.google.com/?q=' +
              data.site.siteMetadata.companyInfo.addressLine1 +
              ' ' +
              data.site.siteMetadata.companyInfo.city +
              ',' +
              data.site.siteMetadata.companyInfo.state +
              ' ' +
              data.site.siteMetadata.companyInfo.zip
            }
            target="_blank"
          >
            <Button color="white">
              {data.site.siteMetadata.companyInfo.addressLine1}{' '}
              {data.site.siteMetadata.companyInfo.city},{' '}
              {data.site.siteMetadata.companyInfo.state}{' '}
              {data.site.siteMetadata.companyInfo.zip}
            </Button>
          </a>
        </div>
      </HeadingArea>
      <div className="container" style={{ marginTop: '-70px' }}>
        <PhotoGallerySection>
          <PhotoSwipeGallery
            items={items}
            options={options}
            thumbnailContent={getThumbnailContent}
          />
        </PhotoGallerySection>
        <div style="text-align:center;margin-top:15px"><h2>Showroom Virtual Tour 360°</h2><iframe src="https://www.google.com/maps/embed?pb=!4v1670283233562!6m8!1m7!1sCAoSLEFGMVFpcE9JTWRneThsVjdLd2ExdnJNWk9XM1hXYU8tQ0tMeUNfM3BScEU1!2m2!1d38.66423623836!2d-121.34333386349!3f302.04301477536376!4f-4.035469637633426!5f0.4000000000000002" width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>


<p>Welcome to Solid Construction &amp; Design, Sacramento's premier kitchen and bath showroom. Our mission is to provide top-notch services and products to our customers, creating the kitchen or bathroom of your dreams. Our showroom is located in the heart of Sacramento, and we are proud to offer quality services to our local community.</p>
<h2>Kitchen &amp; Bath Showroom in Sacramento</h2>
<p>Our showroom is a one-stop-shop for all your kitchen and bathroom renovation needs. We offer a vast selection of products from top manufacturers, making it easy for you to choose the best options for your needs. From kitchen countertops and cabinets to bathroom fixtures and accessories, we have everything you need to create a stunning and functional space.</p>
<p>Our Sacramento kitchen and bath showroom is staffed by experts who are passionate about providing high-quality products and services to our customers. We work with a range of manufacturers, allowing us to offer the best selection of products and the most competitive prices.</p>
<h3>Kitchen Renovations in Sacramento</h3>
<p>At Solid Construction &amp; Design, we understand that a kitchen is the heart of any home. It is where meals are prepared, and families come together. That is why we are dedicated to providing the best kitchen renovation services in Sacramento. Our team of experts can help you plan and design your dream kitchen, taking into consideration your unique needs and preferences.</p>
<p>Our Sacramento kitchen showroom offers a wide range of kitchen cabinets, countertops, and appliances. Whether you are looking for a modern or traditional kitchen, we have the products and expertise to help you create the perfect space. Our team will guide you through the entire process, from design to installation, ensuring that your new kitchen is functional and beautiful.</p>
<h3>Bathroom Renovations in Sacramento</h3>
<p>The bathroom is another essential space in any home. It is a place of relaxation and rejuvenation, and it should reflect your personal style and needs. At Solid Construction &amp; Design, we offer a range of bathroom renovation services to help you create your dream bathroom.</p>
<p>Our Sacramento bath showroom offers a wide selection of products, including bathroom fixtures, vanities, and accessories. Our team of experts will work with you to design a bathroom that meets your needs and reflects your style. From small updates to complete bathroom overhauls, we are here to help you create the bathroom of your dreams.</p>
<h3>Why Choose Solid Construction &amp; Design?</h3>
<p>At Solid Construction &amp; Design, we are dedicated to providing high-quality products and services to our customers. We work directly with manufacturers, which allows us to cut out the middleman and pass on the best prices directly to you. Our team of experts is passionate about helping you create the kitchen or bathroom of your dreams, and we are committed to delivering exceptional customer service.</p>
<p>Our Sacramento kitchen and bath showroom is the perfect place to start your renovation journey. We have a vast selection of products, and our team of experts is here to guide you through the entire process. Whether you are looking for a complete kitchen or bathroom renovation or just a few updates, we are here to help you achieve your goals.</p>
<h2>Visit Our Remodeling Showroom in Sacramento Today</h2>
<p>If you are in Sacramento and looking for a kitchen and bath showroom, look no further than Solid Construction &amp; Design. Our team of experts is here to help you create the kitchen or bathroom of your dreams. Contact us today to schedule a showroom visit, and let us help you get started on your renovation journey.</p>
      </div>
     
    </Layout>
  )
}

export default ShowroomPage

export const pageQuery = graphql`
  query ShowroomPhotos {
    site {
      siteMetadata {
        companyInfo {
          addressLine1
          addressLine2
          city
          state
          zip
        }
      }
    }
    allContentfulPhotoGallery(
      filter: { contentful_id: { eq: "2UPCiBmnDiHj853idmhTTE" } }
    ) {
      edges {
        node {
          id
          title
          description {
            description
          }
          photos {
            id
            title
            gatsbyImageData(width: 1200)
            thumbnail: gatsbyImageData(width: 500, height: 500)
          }
        }
      }
    }
  }
`
